import React from "react";
import loadable from "@loadable/component";

import Skeleton from "components/Skeleton";

const components = {
  pageSectionArtworkGrid: loadable(() =>
    import("page-sections/pageSectionArtworkGrid")
  ),
  pageSectionCompound: loadable(() =>
    import("page-sections/pageSectionCompound")
  ),
  pageSectionDocumentList: loadable(() =>
    import("page-sections/pageSectionDocumentList")
  ),
  pageSectionDocumentLists: loadable(() =>
    import("page-sections/pageSectionDocumentLists")
  ),
  pageSectionForm: loadable(() => import("page-sections/pageSectionForm")),
  pageSectionGallery: loadable(() =>
    import("page-sections/pageSectionGallery")
  ),
  pageSectionHero: loadable(() => import("page-sections/pageSectionHero")),
  pageSectionImage: loadable(() => import("page-sections/pageSectionImage")),
  pageSectionProductGrid: loadable(() =>
    import("page-sections/pageSectionProductGrid")
  ),
  pageSectionText: loadable(() => import("page-sections/pageSectionText")),
  pageSectionYoutubePlaylist: loadable(() =>
    import("page-sections/pageSectionYoutubePlaylist")
  ),
};

const preload = {
  pageSectionHero: loadable(() => import("page-sections/pageSectionHero")),
};

const renderPageSections = (modules = []) => {
  return modules.map(({ _key, ...props }) => {
    const Component = preload[props._type] || components[props._type];
    const children =
      props._type === "pageSectionCompound" &&
      renderPageSections(props.sections);

    if (Component) {
      return (
        <Component
          sx={{ mb: 6 }}
          key={_key}
          fallback={<Skeleton component={props._type} />}
          children={children}
          {...props}
        />
      );
    }

    return <h3 key={_key}>{props._type}</h3>;
  });
};

export default renderPageSections;
